.form-group {
  width: 100%;

  margin-bottom: 20px;
  input {
    width: 100%;
    min-height: 42px;
    border: 1px solid #d8d6de;
    border-radius: 5px;
    padding: 5px 10px;
  }
  input:focus {
    border: 1px solid #7367f0 !important;
  }

  button {
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    color: #fff;
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
  }
}
